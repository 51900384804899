<template>
  <section class="themes__wrapper">
    <div class="container">
      <div class="themes__block">
        <h2 class="page-title page-title_1">
          {{ $getTranslate('themes_block_title') }}
        </h2>

        <div class="themes">
          <div
            v-for="(item, i) in items"
            class="themes__item"
            :key="'item' + i"
          >
            <div class="themes__item-img">
              <img :src="require('@/assets/img/icons/' + item.img)" alt="" />
            </div>

            <div class="themes__item-text" v-text="$getTranslate(item.text)" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ThemesBlock',

  data() {
    return {
      items: [
        {
          img: 'theme-1.svg',
          text: 'theme_title_1'
        },
        {
          img: 'theme-2.svg',
          text: 'theme_title_2'
        },
        {
          img: 'theme-3.svg',
          text: 'theme_title_3'
        },
        {
          img: 'theme-4.svg',
          text: 'theme_title_4'
        },
        {
          img: 'theme-5.svg',
          text: 'theme_title_5'
        },
        {
          img: 'theme-6.svg',
          text: 'theme_title_6'
        },
        {
          img: 'theme-7.svg',
          text: 'theme_title_7'
        },
        {
          img: 'theme-8.svg',
          text: 'theme_title_8'
        }
      ]
    }
  }
}
</script>
