<template>
  <div>
    <div class="container">
      <div class="top-block">
        <div class="top-block__img">
          <img
            src="@/assets/img/bg/bg-advertisers-1.png"
            alt=""
            loading="lazy"
            width="520"
            height="300"
          />
        </div>

        <AttentionBlock
          h1
          :title="$getTranslate(topBlock.title)"
          :text="$getTranslate(topBlock.text)"
        />
      </div>
    </div>

    <div class="color-blocks">
      <ColorBlock :item="colorBlockAdv1" />
      <ColorBlock :item="colorBlockAdv2" />
    </div>
    <PropsBlockAdv />
    <ThemesBlock />
  </div>
</template>

<script>
import PropsBlockAdv from '@/components/PropsBlockAdv.vue'
import ThemesBlock from '@/components/ThemesBlock.vue'

export default {
  name: 'AdvertisersPage',
  components: {
    PropsBlockAdv,
    ThemesBlock
  },

    metaInfo() {
    return {
      title: this.$getTranslate('meta_adv_title'),
            meta: [
                { name: 'description', content:  this.$getTranslate('meta_adv_description')},
                { property: 'og:title', content: this.$getTranslate('meta_adv_title')},
                { property: 'og:site_name', content: 'Costaction'},
                {property: 'og:image', content: window.location.href + require('@/assets/img/bg/bg-advertisers-1.png')},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'index,follow'}
            ]
        }
  },

  data() {
    return {
      colorBlockAdv1: {
        smallPadding: true,
        bg: 'handshake',
        title: 'color_block_title_adv_1',
        text: 'color_block_text_adv_1'
      },

      colorBlockAdv2: {
        red: true,
        smallPadding: true,
        bg: 'target',
        title: 'color_block_title_adv_2',
        text: 'color_block_text_adv_2'
      },

      topBlock: {
        h1: true,
        title: 'top_title_adv',
        text: 'top_text_adv'
      }
    }
  }
}
</script>
