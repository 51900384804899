<template>
  <div class="props__block props__block_v2 oblique oblique_2">
    <div class="container ">
      <div class="props props_v2">
        <div class="props__item props__item_left props__item-text-2">
          <div class="attention-block attention-block_long">
            <h2 class="page-title page-title_2">
              {{ $getTranslate('adv_props_title_1') }}
            </h2>

            <div class="attention-block__text">
              {{ $getTranslate('adv_props_text_1') }}
            </div>

            <a
              href="https://help.costaction.com/"
              class="btn btn_red attention-block__btn"
            >
              {{ $getTranslate('adv_props_btn_1') }}
              <img
                src="@/assets/img/icons/arrow-btn.svg"
                class="btn__icon"
                alt=""
              />
            </a>
          </div>
        </div>

        <div class="props__item props__item_right props__item-img-2">
          <img
            src="@/assets/img/bg/bg-advertisers-2.png"
            loading="lazy"
            width="411"
            height="388"
            alt=""
          />
        </div>

        <div class="props__item props__item_left props__item-img-3">
          <img
            src="@/assets/img/bg/bg-advertisers-3.png"
            loading="lazy"
            width="411"
            height="388"
            alt=""
          />
        </div>

        <div class="props__item props__item_right props__item-text-3">
          <div class="attention-block attention-block_long">
            <h2 class="page-title page-title_2">
              {{ $getTranslate('adv_props_title_2') }}
            </h2>

            <div class="attention-block__text">
              {{ $getTranslate('adv_props_text_2') }}
            </div>

            <a
              href="https://help.costaction.com/"
              class="btn btn_red attention-block__btn"
            >
              {{ $getTranslate('adv_props_btn_2') }}
              <img
                src="@/assets/img/icons/arrow-btn.svg"
                class="btn__icon"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropsBlock'
}
</script>
